import React from "react";
import styles from "./PostFilter.module.css"; // Ensure to create this CSS file
import { useTranslation } from "react-i18next";

function PostFilter({ selectedFilter, onFilterChange }) {
  const { t } = useTranslation();

  const filters = [
    "",
    "consejos",
    "noticias",
    "procedimentos",
    "tendencias",
    "masculino",
  ];
  const filterNames = [
    t("filter1"),
    t("filter2"),
    t("filter3"),
    t("filter4"),
    t("filter5"),
    t("filter7"),
  ];

  return (
    <div className={styles.postFilter}>
      {filters.map((filter, index) => (
        <button
          key={filter}
          className={`${styles.filterButton} ${
            selectedFilter === filter ? styles.active : ""
          }`}
          onClick={() => onFilterChange(filter)}
        >
          {filterNames[index]}
        </button>
      ))}
    </div>
  );
}

export default PostFilter;
