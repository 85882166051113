import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import styles from "./Gift.module.css";
import ModalGift from './ModalGift';

function Gift() {
    // Modal Gift
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className={styles.container}>
            {/* O botão foi removido, e o evento foi adicionado no content */}
            <div 
                className={styles.content} 
                onClick={() => setModalShow(true)}
            >
                <div className={styles.giftContainer}>
                    <DotLottieReact
                        src="https://lottie.host/ef7cd5f7-ae04-4fe6-a9ad-65bf1e17a444/ZhUTciEUNm.lottie"
                        loop
                        autoplay
                        className={styles.gift}
                    />
                </div>
            </div>

            {/* Modal */}
            <ModalGift show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    );
}

export default Gift;
