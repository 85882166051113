import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { Helmet } from "react-helmet";

// Imagem icones
import iconAplicacion from "../assets/aplicacion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";
import iconRecuperacao from "../assets/icons/tiemporecuperacion.png";
import iconDuracao from "../assets/icons/duracion.svg";

import imgLineasCard from "../assets/ArrugasdeExpresion/ArrugasdeExpresionCard.jpg";
import "swiper/css";
import "swiper/css/navigation";

import header from "../assets/ArrugasdeExpresion/ArrugasdeExpresionHeader.webp";
import imgInicio from "../assets/ArrugasdeExpresion/ArrugasdeExpresionText.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CustomSlider from "../components/layout/CustomSlider";

import CardDuvidas from "../components/layout/cardsDuvidas";
import FloatButton from "../components/FloatButton";
import AgendarCita from "../components/Seção_Agendar/AgendarCita";
function ArrugasdeExpresion() {
  const { ref, inView } = useInView({
    triggerOnce: false, // A animação será acionada apenas uma vez
    threshold: 0.1, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  const [refImg, inViewImg] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Ajuste conforme necessário para disparar a animação mais cedo ou mais tarde
  });

  const [animateImg, setAnimateImg] = useState(false);
  useEffect(() => {
    setAnimateImg(inViewImg);
  }, [inViewImg]);

  //Translation
  const { t } = useTranslation();

  const slides = [
    {
      title: t("arrugasTratamiento"),
      img: imgInicio,
      titleList: [t("arrugas1"), t("arrugas2"), t("arrugas3")],
      contentList: [t("areaArrugas1"), t("areaArrugas2"), t("areaArrugas3")],

      textColor: "black", // Texto preto para o primeiro slide
    },
    {
      title: t("beneficioArrugas"),
      img: imgInicio,
      titleList: [
        t("beneficioArrugas1"),
        t("beneficioArrugas2"),
        t("beneficioArrugas3"),
      ],
      contentList: [
        t("benef.TextArrugas1"),
        t("benef.TextArrugas2"),
        t("benef.TextArrugas3"),
      ],
      textColor: "black",
    },
  ];

  const isMobile = window.innerWidth < 769;

  <Helmet>
    <title>
      Elimina Arrugas de Expresión - Tratamientos del Dr. Alex Moreira
    </title>
    <meta
      name="description"
      content="Reduce las arrugas de expresión  con técnicas avanzadas y seguras, logrando un rostro fresco y descansado."
    />
  </Helmet>;

  return (
    <div>
      <Header
        Titulo={t("headerArrugas")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <div className={styles.icon}>
            <img src={iconAplicacion} />
            <p className={styles.titleIcon}>{t("iconArrugas1")}</p>

            <p className={styles.subtitleIcon}>{t("descArrugasIcon1")}</p>
          </div>
          <div className={styles.icon}>
            <img src={iconAnestesia} />
            <p className={styles.titleIcon}>{t("iconArrugas2")}</p>
            <p className={styles.subtitleIcon}>{t("descArrugasIcon2")}</p>
          </div>
        </div>
        <div className={styles.iconsTexto}>
          <div className={styles.icon}>
            <img src={iconRecuperacao} />
            <p className={styles.titleIcon}>{t("iconArrugas3")}</p>
            <p className={styles.subtitleIcon}>{t("descArrugasIcon3")}</p>
          </div>
          <div className={styles.icon}>
            <img src={iconDuracao} />
            <p className={styles.titleIcon}>{t("iconArrugas4")}</p>
            <p className={styles.subtitleIcon}>{t("descArrugasIcon4")}</p>
          </div>
        </div>
        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>
        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro} ${
              animateImg ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={refImg}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animateImg ? ` ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("titleIntroArrugas")}
            <p
              className={`${styles.subtitleIntro} ${
                animate ? ` ${styles.slideLeft}` : ""
              }`}
              ref={ref}
            >
              {t("subTitleArrugas")}
            </p>
          </h2>

          <p>{t("textArrugas")}</p>
        </div>
      </div>

      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "320px"]}>
          <h1 className={styles.titlePrincipalContainer}>
            {t("titleCardArrugas")}
          </h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("cardTitleArrugas01")}
              content={[t("cardArrugas01")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLineasCard}
            />
            <CardDuvidas
              title={t("cardTitleArrugas02")}
              content={[t("cardArrugas02")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLineasCard}
            />
            <CardDuvidas
              title={t("cardTitleArrugas03")}
              content={[t("cardArrugas03")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLineasCard}
            />
          </div>
        </div>
      </div>

      <div className={styles.containerProcesso}>
        <div>
          <div
            className={`${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            id={styles.cardTratamiento}
            ref={ref}
          >
            <div className={styles.contentTratamiento}>
              <p className={styles.titlePrincipal}>
                {t("expectativaArrugas01")}
              </p>
              <p className={styles.conteudoTratamiento}>
                {t("expectativaArrugas02")}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("procesoArrugas")}</h4>
          <div>
            <ul>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {t("consultArrugas01")}
                    </p>
                    <p>{t("consultArrugas02")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {t("aplicacionArrugas01")}
                    </p>
                    <p>{t("aplicacionArrugas02")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {t("cuidadoArrugas01")}
                    </p>
                    <p>{t("cuidadoArrugas02")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.swiperContainer}>
        <CustomSlider slides={slides} showTitleCard={false} />
      </div>

      <div className={styles.containerPlus}>
        <h4>{t("titleOpcionesH4")}</h4>
        <div className={styles.contentPlus}>
          <div className={styles.circlePlus}>
            <a href="/Lifting"> {t("linkLifting")}</a>
          </div>
          <div className={styles.circlePlus}>
            <a href="/SurcoNasogeniano"> {t("linkSurco")}</a>
          </div>
          <div className={styles.circlePlus}>
            <a href="/Ojeras"> {t("linkOjeras")}</a>
          </div>
        </div>
        <div>
          <Button className={styles.button} id={styles.buttonPlus}>
            <a className={styles.linkServicios} href="/Servicios">
              {t("linkTratamientos")}
            </a>
          </Button>
        </div>
      </div>

      <AgendarCita />

      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("preguntasArrugas")} </h2>
          <Perguntas
            pergunta={t("preguntasArrugas1")}
            resposta={t("respuestaArrugas1")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("preguntasArrugas2")}
            resposta={t("respuestaArrugas2")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("preguntasArrugas3")}
            resposta={t("respuestaArrugas3")}
            eventKey="2"
          />
          <Perguntas
            pergunta={t("preguntasArrugas4")}
            resposta={t("respuestaArrugas4")}
            eventKey="3"
          />
          <Perguntas
            pergunta={t("preguntasArrugas5")}
            resposta={t("respuestaArrugas5")}
            eventKey="4"
          />
        </div>
      </div>
    </div>
  );
}

export default ArrugasdeExpresion;
