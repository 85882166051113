import Header from "../components/layout/Header";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { useInView } from "react-intersection-observer";

import header from "../assets/Ojeras/OjerasHeaderCort1.webp";
import imgInicio from "../assets/Ojeras/OjerastextCort1.webp";
import imgLiftingCard from "../assets/Ojeras/OjerastextCort1.webp";
import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import FloatButton from "../components/FloatButton";
//Translation
import { useTranslation } from "react-i18next";

//Components
import AgendarCita from "../components/Seção_Agendar/AgendarCita";

function Ojeras() {
  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.5, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();
  <Helmet>
    <title>
      Elimina Ojeras - Soluciones Personalizadas del Dr. Alex Moreira
    </title>
    <meta
      name="description"
      content="Suaviza y elimina las ojeras con tratamientos estéticos avanzados que revitalizan tu mirada."
    />
  </Helmet>;

  return (
    <div>
      <Header
        Titulo={t("Ojeras01")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("Ojeras02")}
            subtitle={t("Ojeras03")}
          />
          <Icon
            src={iconAnestesia}
            title={t("Ojeras04")}
            subtitle={t("Ojeras05")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("Ojeras06")}
            subtitle={t("Ojeras07")}
          />
          <Icon
            src={iconDuracion}
            title={t("Ojeras08")}
            subtitle={t("Ojeras09")}
          />
        </div>

        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={ref}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("Ojeras10")}
            <p
              className={`${styles.subtitleIntro} ${
                animate ? ` ${styles.slideLeft}` : ""
              }`}
              ref={ref}
            >
              {t("Ojeras11")}
            </p>
          </h2>

          <p>{t("Ojeras12")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "320px"]}>
          <h1 className={styles.titlePrincipalContainer}> {t("Ojeras13")}</h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("Ojeras14")}
              content={[t("Ojeras15")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Ojeras16")}
              content={[t("Ojeras17")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Ojeras18")}
              content={[t("Ojeras19")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div
            className={`${styles.cardShort} ${styles.slideRight}`}
            id={styles.cardTratamiento}
          >
            <div className={styles.contentCard}>
              <p className={styles.titlePrincipal}> {t("Ojeras40")} </p>
              <p className={`${styles.conteudo} ${styles.conteudoAdd}`}>
                <span key="1" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Ojeras41")}
                  </strong>{" "}
                  {t("Ojeras42")}
                </span>
                <br />
                <span key="4" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Ojeras43")}
                  </strong>{" "}
                  {t("Ojeras44")}
                </span>
                <br />
                <span key="2" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Ojeras45")}
                  </strong>{" "}
                  {t("Ojeras46")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("Ojeras20")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Ojeras21")}</p>
                    <p>{t("Ojeras22")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}> {t("Ojeras23")} </p>
                    <p>{t("Ojeras24")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Ojeras25")}</p>
                    <p>{t("Ojeras26")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.containerPlus}>
        <h4> {t("Ojeras27")} </h4>
        <div className={styles.contentPlus}>
          <div className={styles.circlePlus}>
            <a href="/ArrugasdeExpresion"> {t("Ojeras28")} </a>
          </div>
          <div className={styles.circlePlus}>
            <a href="/lifting"> {t("Ojeras29")} </a>
          </div>
          <div className={styles.circlePlus}>
            <a href="SurcoNasogeniano"> {t("Ojeras30")} </a>
          </div>
        </div>
        <div>
          <Button className={styles.button} id={styles.buttonPlus}>
            <a className={styles.linkServicios} href="/Servicios">
              {t("linkTratamientos")}
            </a>
          </Button>
        </div>
      </div>
      <AgendarCita />
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("Ojeras31")} </h2>
          <Perguntas
            pergunta={t("Ojeras32")}
            resposta={t("Ojeras33")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("Ojeras34")}
            resposta={t("Ojeras35")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("Ojeras36")}
            resposta={t("Ojeras37")}
            eventKey="2"
          />
          <Perguntas
            pergunta={t("Ojeras38")}
            resposta={t("Ojeras39")}
            eventKey="3"
          />
        </div>
      </div>
    </div>
  );
}

export default Ojeras;
